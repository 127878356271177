@use '@angular/material' as mat;
@use 'sass:map';

@mixin favourite-heart-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $primary-color: mat.get-color-from-palette($primary-palette, default);

  .favourite-heart.favourite {
    &:hover {
      .mat-icon {
        color: $primary-color;
      }
    }

    &.selected {
      .mat-icon {
        color: $primary-color;
      }
    }
  }
}
