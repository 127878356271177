@use '@angular/material' as mat;
@use 'sass:map';

@mixin checkbox-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $primary-color: mat.get-color-from-palette($primary-palette, default);

  .syncee-blue-checkbox {
    &.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
      background-color: $primary-color !important;
    }
  }
}
