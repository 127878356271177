$border-radius: 40px;

@mixin chips-theme($theme) {
  .general-chip {
    border-radius: $border-radius !important;
    padding: 3px 7px !important;

    &.mat-chip::after {
      content: unset !important;
    }

    .mat-icon {
      @extend .s-16
    }
  }

  .grey-chip {
    @extend .general-chip;
    @extend .typography-label;

    background: var(--app-syncee-grey-100) !important;
    border: 1px solid var(--app-syncee-grey-100) !important;

    &:hover {
      background: var(--app-syncee-grey-200) !important;
      opacity: 1 !important;
    }

    .mat-chip-remove {
      opacity: 1 !important;
    }

    .mat-icon {
      color: var(--app-black-500) !important;
      opacity: 1 !important;

      &:hover {
        color: var(--app-black-500) !important;
      }
    }
  }

  .light-blue-chip {
    @extend .general-chip;
    @extend .typography-label;

    background: var(--app-syncee-primary-50) !important;
    color: var(--app-syncee-primary-700) !important;
    border: 1px solid var(--app-syncee-primary-50) !important;

    &:hover {
      background: var(--app-syncee-primary-100) !important;
      opacity: 1 !important;
    }

    .mat-chip-remove {
      opacity: 1 !important;
    }

    .mat-icon {
      color: var(--app-syncee-primary-700) !important;
      opacity: 1 !important;

      &:hover {
        color: var(--app-syncee-primary-700) !important;
      }
    }
  }

  .white-chip {
    @extend .general-chip;
    @extend .typography-label;

    background: var(--app-white-500) !important;
    border: 1px solid var(--app-black-500) !important;

    &:hover {
      background: var(--app-syncee-grey-50) !important;
      opacity: 1 !important;
    }

    .mat-chip-remove {
      opacity: 1 !important;
    }

    .mat-icon {
      color: var(--app-black-500) !important;
      opacity: 1 !important;

      &:hover {
        color: var(--app-black-500) !important;
      }
    }
  }
}
