@use 'sass:map';
// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------

// Major palette
$white: (
  500: white,
  contrast: (
    500: $dark-primary-text,
  ),
);

$black: (
  500: #212121,
  contrast: (
    500: $light-primary-text,
  ),
);

$syncee-primary: (
  50: #e2effb,
  100: #d4e2fe,
  200: #8cc9ff,
  300: #57b3ff,
  400: #2aa1ff,
  500: #0090ff,
  600: #1b81ff,
  700: #286df8,
  800: #2e5ae5,
  900: #3436c5,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$syncee-warn: mat.$red-palette;

$syncee-grey: (
  50: #f9f9f9,
  100: #f2f2f2,
  200: #e9e9e9,
  300: #d9d9d9,
  400: #b5b5b5,
  500: #959595,
  600: #6d6d6d,
  700: #5a5a5a,
  800: #3b3b3b,
  900: #1b1b1b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

//Custom palette
$light-green: mat.$light-green-palette;

$green: mat.$green-palette;

$amber: mat.$amber-palette;

$alibaba: (
  50: #fff8e0,
  100: #ffebb2,
  200: #ffde80,
  300: #ffd24c,
  400: #ffc723,
  500: #ffbd00,
  600: #ffaf00,
  700: #ff9c00,
  800: #ff8b00,
  900: #ff6a00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$light-beige: (
  50: #f8f0e5,
  100: #f3d8b5,
  200: #efbe7e,
  300: #eaa448,
  400: #e79220,
  500: #e38000,
  600: #df7500,
  700: #d96600,
  800: #d25600,
  900: #c93b00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$ashy-beige: (
  50: #f5eee4,
  100: #e8d5bc,
  200: #deb988,
  300: #d79d51,
  400: #d2892c,
  500: #cc770d,
  600: #c86d0c,
  700: #c26009,
  800: #bb5105,
  900: #b13801,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$turquoise: (
  50: #d7eeee,
  100: #9ad5d3,
  200: #56b9b5,
  300: #009d97,
  400: #008982,
  500: #00756c,
  600: #006960,
  700: #005b51,
  800: #004b42,
  900: #003127,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$neon-yellow: (
  50: #fbffea,
  100: #f4fdc8,
  200: #ebfca2,
  300: #e8ff81,
  400: #e0fc62,
  500: #d9f845,
  600: #d0e841,
  700: #bfd038,
  800: #afb82f,
  900: #959120,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$lilac: (
  50: #f6ecfe,
  100: #e6cffd,
  200: #d5b0f9,
  300: #c38df2,
  400: #b471eb,
  500: #a656e4,
  600: #9b50dc,
  700: #8e46d2,
  800: #8340ca,
  900: #7132bc,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  white: $white,
  black: $black,
  syncee-primary: $syncee-primary,
  syncee-warn: $syncee-warn,
  syncee-grey: $syncee-grey,
  light-green: $light-green,
  green: $green,
  amber: $amber,
  alibaba: $alibaba,
  light-beige: $light-beige,
  ashy-beige: $ashy-beige,
  turquoise: $turquoise,
  neon-yellow: $neon-yellow,
  lilac: $lilac,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

$typography-for-angular: mat.define-typography-config(
  $font-family: "'Inter', sans-serif",
  $headline: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
);
@import 'src/styles/custom-typographies/typographies';
@import 'src/styles/custom-typographies/landing-typographies';
@import 'src/styles/custom-typographies/generate-typography-classes';
$typography-config: map.merge($typography-for-angular, $custom-typographies);
$typography-config: map.merge($typography-config, $custom-landing-typographies);
@include generate-typography-classes($typography-config);

// Setup the typography
@include mat.all-component-typographies($typography-config);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import 'src/app/layout/components/toolbar/toolbar.theme';
@import 'src/app/main/authentication/login/login-theme';
@import 'src/app/main/authentication/registration/registration.theme';
@import 'src/app/main/billing/subscription/subscription.theme';
@import 'src/app/main/manage-orders-new/retailer-manage-orders/retailer-manage-orders.theme.scss';
@import 'src/app/layout/components/footer/footer.theme';
@import 'src/app/main/marketplace/marketplace.theme';
@import 'src/styles/styles.theme';
@import 'src/app/shared/components/shared-components.theme';
@import 'src/app/main/product-card/product-card.theme';
@import 'src/app/main/review/review.theme';
@import 'src/app/main/notification/notification.theme';
@import 'src/app/main/billing-new/subscription/theme/subscription-new.theme';
@import 'src/app/main/setup-guide/setup-guide.theme';
@import 'src/app/main/retailer-import-list/retailer-import-list.theme';
@import 'src/app/main/getting-started-new/getting-started.theme';
@import 'src/app/main/static/static.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include shared-components-theme($theme);
  @include toolbar-theme($theme);
  @include login-theme($theme);
  @include registration-theme($theme);
  @include subscription-theme($theme);
  @include retailer-manage-orders-theme($theme);
  @include footer-theme($theme);
  @include marketplace-theme($theme);
  @include product-card-theme($theme);
  @include review-theme($theme);
  @include notification-theme($theme);
  @include subscription-new-theme($theme);
  @include setup-guide-theme($theme);
  @include retailer-import-list-theme($theme);
  @include getting-started-theme($theme);
  @include static-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($syncee-primary, 700);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
//$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $default-primary-palette,
      accent: $default-accent-palette,
      warn: $default-warn-palette,
    ),
  )
);

$custom-background-color: #ffffff;
$custom-disabled-button-background: rgba(60, 66, 82, 0.12);

$background: map-get($theme, background);
$background: map_merge(
  $background,
  (
    background: $custom-background-color,
    disabled-button: $custom-disabled-button-background,
  )
);
$theme: map_merge(
  $theme,
  (
    background: $background,
  )
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map
  @include mat.all-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  @include styles-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($syncee-primary, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme(
  $yellow-light-theme-primary-palette,
  $yellow-light-theme-accent-palette,
  $yellow-light-theme-warn-palette
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
  // Generate the Angular Material theme
  @include mat.all-component-themes($yellow-light-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($yellow-light-theme);

  // Apply the theme to the user components
  @include components-theme($yellow-light-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme(
  $blue-gray-dark-theme-primary-palette,
  $blue-gray-dark-theme-accent-palette,
  $blue-gray-dark-theme-warn-palette
);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($blue-gray-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($blue-gray-dark-theme);

  // Apply the theme to the user components
  @include components-theme($blue-gray-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme(
  $pink-dark-theme-primary-palette,
  $pink-dark-theme-accent-palette,
  $pink-dark-theme-warn-palette
);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($pink-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($pink-dark-theme);

  // Apply the theme to the user components
  @include components-theme($pink-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

.mat-card {
  box-shadow: unset !important;
  border: 1px solid map-get($syncee-grey, 200);
  border-radius: 8px !important;
  padding: 20px !important;
}

//Input field
body.theme-default .mat-input-element {
  caret-color: #4977b0;
}

body.theme-default .mat-form-field-appearance-legacy .mat-form-field-underline,
body.theme-default .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #4977b0;
}

.fuse-card {
  max-width: 100%;
}

.mat-form-field {
  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: transparent !important;
      border: 1px solid map-get($black, 500);
      border-radius: 8px;
      padding-left: 1.25em;
      padding-right: 1.25em;
    }
    &.mat-form-field-disabled {
      .mat-form-field-flex {
        background: var(--app-grey-50) !important;
        border-color: var(--app-grey-200);
      }
    }
    &:not(.select-without-label) {
      .mat-form-field-flex {
        padding: 0.55em 1.25em 0 1.25em !important;
      }

      .mat-form-field-infix {
        padding: 0.15em 0 0.55em 0 !important;
      }
    }
  }

  .mat-form-field-underline {
    //width: calc(100% - 36px);
    left: 50%;
    transform: translateX(-50%) scale3d(1, 1.0001, 1);

    &::before {
      background-color: unset !important;
    }

    .mat-form-field-ripple {
      width: calc(100% - 8px);
      left: 50%;
      transform: translateX(-50%) translateY(0) !important;
      background-color: map-get($syncee-grey, 400) !important;
    }
  }

  &.mat-focused {
    .mat-form-field-ripple {
      background-color: map-get($syncee-grey, 400) !important;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-ripple {
      background-color: #f44336 !important;
    }
  }
}

.mat-expansion-panel {
  box-shadow: unset !important;
  border: 1px solid map-get($syncee-grey, 200);
}

.mat-menu-content {
  padding: 8px;
}

.mat-menu-item {
  border-radius: 12px;
  height: 44px !important;
  line-height: 44px !important;
  margin: 4px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.mat-button,
.mat-flat-button,
.mat-stroked-button {
  border-radius: 50px !important;
}

.mat-tab-label {
  opacity: 1 !important;
}

.mat-radio-button {
  &.mat-radio-checked {
    .mat-radio-container {
      .mat-radio-outer-circle {
        border-color: map-get($syncee-primary, 700) !important;
      }

      .mat-radio-inner-circle {
        background-color: map-get($syncee-primary, 700) !important;
      }
    }
  }

  .mat-radio-container {
    .mat-radio-outer-circle {
      border-color: inherit !important;
    }
  }
}

.mat-radio-button {
  .mat-radio-label-content {
    font-size: 14px;
    padding-top: 3px;
  }
}

.mat-checkbox {
  .mat-checkbox-label {
    font-size: 14px;
    line-height: 24px;
  }

  &.mat-checkbox-disabled {
    .mat-checkbox-frame {
      border-color: map-get($syncee-grey, 300) !important;
    }
  }
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: map-get($syncee-grey, 200) !important;
}

@import 'styles/custom-colors/palette';
@import 'styles/custom-colors/generate-variable';
@import 'styles/custom-colors/generate-classes';
@include generate-custom-color-variables('custom', $custom-colors);
@include generate-custom-color-classes('custom', $custom-colors);
