@use 'sass:map';

@mixin expansion-panel-theme($theme) {
  //.mat-accordion {
  //  .mat-expansion-panel {
  //     border-radius: 8px;
  //  }
  //
  //  .mat-expansion-panel-header-title {
  //     font-size: 20px;
  //     font-weight: 500;
  //  }
  //}
  .without-border {
    border: 0;
  }

  .accordion-without-any-border.mat-accordion {
    .mat-expansion-panel {
      border: none;
      border-radius: 0;

      &.mat-expanded {
        .mat-expansion-panel-header {
          margin-bottom: 28px;
        }
      }
      .mat-expansion-panel-header {
        height: fit-content;
        padding: 0;
        transition: margin-bottom 300ms ease-in-out;
        h3 {
          margin: 0;
        }
        &:hover {
          background: none;
        }
      }
      .mat-expansion-indicator {
        margin-right: 4px;
      }
      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }
  }
}
