@mixin radio-buttons-theme($theme) {
  .black-radio-button {
    &.mat-radio-button.mat-radio-checked {
      .mat-radio-persistent-ripple {
        background-color: var(--app-black-500) !important;
      }

      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: var(--app-black-500) !important;
        }
        .mat-radio-inner-circle {
          background-color: var(--app-black-500) !important;
        }
      }
    }
  }


  .mat-radio-button.mat-radio-disabled {
    .mat-radio-container {
      .mat-radio-outer-circle {
        border-color: var(--app-syncee-grey-500) !important;
      }
      .mat-radio-inner-circle {
        background-color: var(--app-syncee-grey-500) !important;
      }
    }
  }
}
