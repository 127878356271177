@use '@angular/material' as mat;
@use 'sass:map';

@mixin setup-guide-item-list-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .setup-guide-item-list {
    .ready-step {
      background: mat.get-color-from-palette($primary-palette, 50);

      .mat-icon {
        color: mat.get-color-from-palette($primary-palette, default);
      }
    }
  }
}
