@mixin tab-theme($theme) {
  .mat-tab, .mat-tab-nav-bar {
    .mat-tab-header-pagination-before {
      box-shadow: unset;
    }
    .mat-tab-header-pagination-after {
      box-shadow: unset;
    }
  }

  .tab-label-min-width-unset {
    .mat-tab-label {
    min-width: unset;
    }
  }
}
