@import "palette";

@mixin generate-custom-color-classes($prefix, $colors-map) {
  @each $key, $value in $colors-map {
    .#{"" + $prefix}-#{$key}-bg {
      background-color: #{$value} !important;
    }

    .#{"" + $prefix}-#{$key}-fg {
      color: #{$value} !important;
    }

    .#{"" + $prefix}-#{$key}-border {
      border-color: #{$value} !important;
    }

    .#{"" + $prefix}-#{$key}-border-top {
      border-top-color: #{$value} !important;
    }

    .#{"" + $prefix}-#{$key}-border-bottom {
      border-bottom-color: #{$value} !important;
    }

    .#{"" + $prefix}-#{$key}-border-right {
      border-right-color: #{$value} !important;
    }

    .#{"" + $prefix}-#{$key}-border-left {
      border-left-color: #{$value} !important;
    }
  }
}
