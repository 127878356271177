@import 'components/product-search-with-autocomplete/product-search-with-autocomplete.theme';
@import 'components/notifications/notifications.theme';
@import 'components/extra-icons/extra-icons.theme';
@import 'components/setup-guide-button/setup-guide-button.theme';
@import 'components/catalog-overlay/catalog-overlay.theme';
@import 'components/profile-mobile-dialog/profile-mobile-dialog.theme';

@mixin toolbar-theme($theme) {
  @include product-search-with-autocomplete-theme($theme);
  @include notifications-theme($theme);
  @include extra-icons-theme($theme);
  @include setup-guide-button-theme($theme);
  @include catalog-overlay-theme($theme);
  @include profile-mobile-dialog-theme($theme);

  $background: map-get($theme, background);

  .mat-toolbar {
    background: map-get($background, background);
  }
}
