@use '@angular/material' as mat;

@mixin plan-card-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  app-plan-card {
    .mat-radio-outer-circle {
      color: var(--app-syncee-grey-500);
    }

    .mat-radio-inner-circle {
      background-color: var(--app-syncee-grey-500) !important;
    }

    mat-card {
      &.selected {
        border-color: mat.get-color-from-palette($primary-palette, default);

        .mat-radio-outer-circle {
          color: mat.get-color-from-palette($primary-palette, default);
        }

        .mat-radio-inner-circle {
          background-color: mat.get-color-from-palette($primary-palette, default) !important;
        }

        .plan-price {
          .price {
            color: mat.get-color-from-palette($primary-palette, default);
          }
        }
      }

      &.current {
        .mat-check-icon-wrapper {
          color: mat.get-color-from-palette($primary-palette, default);
        }
      }
    }

    .plan-price {
      color: var(--app-syncee-grey-500);
    }
  }
}
