@mixin marketplace-view-selector-theme($theme) {
  $foreground: map-get($theme, foreground);
  $selected-color: map-get($foreground, text);
  .border-color {
    border-color: var(--app-syncee-grey-200);
    &.selected {
      border-color: $selected-color !important;
    }
  }
}
