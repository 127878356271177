@use '@angular/material' as mat;
@use 'sass:map';

@mixin getting-started-toolbar-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');
  $background: map-get($theme, 'background');
  $background-color: map-get($background, 'background');

  .getting-started-toolbar {
    background-color: mat.get-color-from-palette($primary-palette, default);
    color: $background-color;
  }
}
