@use 'sass:map';
@use '@angular/material' as mat;

@mixin product-prices-guest($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .product-price-guest-container {
    color: mat.get-color-from-palette($primary-palette, default);
  }
}
