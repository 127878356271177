@use 'sass:map';
@use '@angular/material' as mat;

@mixin buttons-theme($theme) {
  $background: map-get($theme, background);
  $disabled-button-background: map-get($background, disabled-button);

  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .syncee-blue-button {
    background-color: mat.get-color-from-palette($primary-palette, default);

    &:not(:disabled) {
      color: white !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-warning-button {
    background-color: var(--app-amber-600);

    &:not(:disabled) {
      color: white !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-success-button {
    background-color: var(--app-light-green-700);

    &:not(:disabled) {
      color: white !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-info-button {
    background-color: mat.get-color-from-palette($primary-palette, default);

    &:not(:disabled) {
      color: white !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-danger-button {
    background-color: var(--app-red-400);

    &:not(:disabled) {
      color: white !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-green-button {
    background-color: var(--app-custom-green-button);

    &:not(:disabled) {
      color: white !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-white-button {
    background-color: var(--app-white-500);
    border: 1px solid var(--app-black-500) !important;

    &:not(:disabled) {
      background-color: var(--app-white-500);
    }

    &:disabled {
      background-color: var(--app-syncee-grey-100);
      border-color: var(--app-syncee-grey-100) !important;
      color: var(--app-syncee-grey-600);
    }
  }

  .syncee-full-white-button {
    background-color: transparent !important;
    color: var(--app-white-500) !important;
    border: 1px solid var(--app-white-500) !important;

    &:not(:disabled) {
      background-color: var(--app-white-500);
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-black-button {
    background-color: #000 !important;
    color: #fff !important;

    &:disabled {
      background-color: rgba(60, 66, 82, 0.12) !important;
      color: rgba(255, 255, 255, 0.26) !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }
  }

  .alibaba-orange-button {
    background-color: var(--app-custom-alibaba-900) !important;
    color: #fff !important;

    &:disabled {
      background-color: rgba(60, 66, 82, 0.12) !important;
      color: rgba(255, 255, 255, 0.26) !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-black-flat-button {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #000 !important;

    &:disabled {
      background-color: rgba(60, 66, 82, 0.12) !important;
      color: rgba(255, 255, 255, 0.26) !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
      border: none !important;
    }
  }

  .syncee-light-blue-button {
    background-color: mat.get-color-from-palette($primary-palette, 50);

    &:not(:disabled) {
      color: mat.get-color-from-palette($primary-palette, default) !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-light-red-button {
    background-color: var(--app-red-100);

    &:not(:disabled) {
      color: var(--app-red-400);
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .syncee-grey-button {
    background-color: var(--app-syncee-grey-100);

    &:not(:disabled) {
      color: var(--app-black-500);
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .larger-button {
    height: 56px;
    min-width: 160px;
    padding: 0 40px;
  }

  .landing-page-button {
    min-width: min-content;
  }

  .input-suffix-button {
    min-width: 0 !important;
    padding: 0 !important;

    &:hover {
      background: transparent !important;
    }

    .mat-button-focus-overlay {
      background: transparent !important;
    }
  }

  .syncee-square-icon-button {
    background-color: var(--app-white-500);
    color: var(--app-black-500);
    border: 1px solid var(--app-syncee-grey-300);
    border-radius: 8px !important;
    padding: 8px !important;
    width: unset !important;
    min-width: 36px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .syncee-action-button {
    border: 1px solid var(--app-black-500);
    border-radius: 8px !important;
    padding: 0 16px 0 24px !important;
    background-color: transparent;
  }

  .syncee-remove-button-layout {
    padding:  0 !important;
    line-height: unset !important;

    .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }

  .syncee-button-arrow {
    &:hover {
      &::after {
        margin-left: 16px;
      }
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='66' height='12' viewBox='0 0 66 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M60.7574 0.696721L65.5303 5.46969C65.8232 5.76258 65.8232 6.23746 65.5303 6.53035L60.7574 11.3033C60.4645 11.5962 59.9896 11.5962 59.6967 11.3033C59.4038 11.0104 59.4038 10.5356 59.6967 10.2427L63.1893 6.75002H1C0.585786 6.75002 0.25 6.41424 0.25 6.00002C0.25 5.58581 0.585786 5.25002 1 5.25002H63.1893L59.6967 1.75738C59.4038 1.46449 59.4038 0.989614 59.6967 0.696721C59.9896 0.403827 60.4645 0.403827 60.7574 0.696721Z' fill='%23212121'/%3E%3C/svg%3E%0A");
      display: inline-block;
      margin-left: 8px;
      transition: margin .15s linear;
      vertical-align: middle;
      width: 36px;
    }
  }

  .syncee-cta-button {
    background-color: var(--app-white-500);
    color: var(--app-black-500);

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}
