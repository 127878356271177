@use 'sass:map';
@use '@angular/material' as mat;
@import 'product-details-supplier-shipping/product-details-supplier-shipping.theme';

@mixin product-details-theme($theme) {
  @include product-details-supplier-shipping-theme($theme);

  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .product-details-container {
    .highlight {
      color: mat.get-color-from-palette($primary-palette, default);
    }
  }
}
