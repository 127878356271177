@use '@angular/material' as mat;
@use 'sass:map';

@mixin stepflow-row-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $primary-50: mat.get-color-from-palette($primary-palette, 50);

  .stepflow-row-container {
    .inner-container {
      .icon-container-outside {
        background-color: rgba($primary-50, 0.4);
      }
    }
  }
}
