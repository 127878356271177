@use 'sass:map';

@mixin ck-editor-theme($theme) {
  .editor-with-border-radius {
    border-radius: 8px;

    > div {
      overflow: hidden;
    }

    .ck-toolbar {
      border-radius: 8px 8px 0 0 !important;
    }

    .ck-content {
      border-radius: 0 0 8px 8px !important;
    }
  }
}
