@use '@angular/material' as mat;

@mixin review-badge-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .new-reviews {
    &.review-badge {
      color: mat.get-color-from-palette($primary-palette, default);
      background-color: mat.get-color-from-palette($primary-palette, 50);
    }
  }
}
