@mixin subscription-summary-theme($theme) {
  $borderColor: var(--app-syncee-grey-200);

  app-subscription-summary {
    .subscription-plans-wrapper {
      border: 1px solid $borderColor;
    }

    .total-amount-wrapper {
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }
  }
}
