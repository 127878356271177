@import 'image-selector/image.selector.theme';
@import 'product-details/product-details.theme';
@import 'product-details/product-prices-guest/product-prices-guest.theme';

@mixin product-card-theme($theme) {
  @include image-selector-theme($theme);
  @include product-details-theme($theme);
  @include product-prices-guest($theme);

  $background: map-get($theme, background);

  .product-card-container {
    background: map-get($background, background);
  }
}
