@use '@angular/material' as mat;
@use 'sass:map';

@mixin profile-mobile-dialog-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .profile-mobile-dialog {
    .item {
      .mat-icon.mat-icon-color {
        color: mat.get-color-from-palette($primary-palette, default);
      }
    }
  }
}
