@mixin manage-orders-table-filter-row-theme($theme) {
  $background: map-get($theme, background);
  .manage-orders.from-to-date-picker-container {
    background: map-get($background, background);
    width: fit-content;
    padding: 1em;
    border: thin solid var(--app-grey-200);
    border-radius: 4px;
  }
}
