@mixin progress-bar-theme($theme) {
    .mat-progress-bar {
        border-radius: 2px;
    
        .mat-progress-bar-fill {
          &::after {
            background-color: var(--app-syncee-primary-700);
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
          }
        }
    
        .mat-progress-bar-buffer {
          background-color: var(--app-syncee-grey-200);
        }
      }
}
