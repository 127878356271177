@use 'sass:map';
@use '@angular/material' as mat;

@mixin footer-theme($theme) {
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  .test {
    background-color: mat.get-color-from-palette($primary-palette, 100);
  }
}
