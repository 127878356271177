@mixin subscription-theme($theme) {
  $foreground: map-get($theme, foreground);

  subscription {
    #price-tables {
      .faq {
        .item {
          .answer {
            color: map-get($foreground, secondary-text);
          }
        }
      }
    }
  }
}
