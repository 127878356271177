@use '@angular/material' as mat;
@use 'sass:map';

@mixin notification-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .top-notification-bar {
    &.success {
      &.background {
        background-color: var(--app-light-green-100);
      }
      .color-light {
        color: var(--app-green-500);
      }
      .color-dark {
        color: var(--app-green-700);
      }
    }

    &.warning {
      &.background {
        background-color: var(--app-amber-100);
      }
      .color-light {
        color: var(--app-amber-600);
      }
      .color-dark {
        color: var(--app-amber-800);
      }
    }

    &.error {
      &.background {
        background-color: var(--app-red-100);
      }
      .color-light {
        color: var(--app-red-300);
      }
      .color-dark {
        color: var(--app-red-400);
      }
    }

    &.information {
      &.background {
        background-color: mat.get-color-from-palette($primary-palette, 50);
      }
      .color-light {
        color: mat.get-color-from-palette($primary-palette, 300);
      }
      .color-dark {
        color: mat.get-color-from-palette($primary-palette, default);
      }
    }
  }
}
