@use '../../../../../../../node_modules/@angular/material/index' as mat;
@use 'sass:map';

@mixin setup-guide-button-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .setup-guide.toolbar {
    .mat-icon {
      color: mat.get-color-from-palette($primary-palette, default);
    }
  }
}
