// Import Fuse core library
@use '../../node_modules/@angular/cdk/index' as cdk;
@import "../@fuse/scss/core";
// Import app.theme.scss
@import "../app/app.theme";
@import "../../node_modules/@angular/cdk/overlay-prebuilt.css";
//@import "../../node_modules/ng-pick-datetime/assets/style/picker.min.css";
@import '../../node_modules/@ctrl/ngx-emoji-mart/picker';

@include cdk.overlay();

$syncee-blue-400: #2AA1FF;
$color-unpaid: #ffaeae;
$color-partially-paid: #ffc58b;
$color-paid: #e2e2e2;
$syncee-grey-400: #B5B5B5;
$syncee-grey-300: #959595;
$darkish-grey: rgba(0, 0, 0, 0.6);


html {
  font-family: 'Inter', sans-serif;
}

mat-icon {
  font-family: 'Material Icons Outlined' !important;
}

.container-full {
  width: 100%;
  height: 100%;
  overflow: auto;
  flex: 0 0 0px;
}

.draggable {
  cursor: move;
  user-select: none;
}

.custom-modal-container
{

  .mat-dialog-container {
    padding: 0;
  }
}

.custom-modal-full-width-lt-md {

  @extend .custom-modal-container;

  @include media-breakpoint('lt-md') {
    min-width: 100% !important;
    min-height: 100% !important;
    height: 100%;
    width: 100%;
    .mat-dialog-content {
      max-height: unset !important;
    }
  }

}

.custom-modal-overflow-auto {

 overflow: auto;

}

.custom-modal-full-width-lt-sm {

  @extend .custom-modal-container;

  @include media-breakpoint('lt-sm') {
    min-width: 100% !important;
    min-height: 100% !important;
    height: 100%;
    width: 100%;
    .mat-dialog-content {
      max-height: unset !important;
    }
  }

}

.mat-tab-label-container {
  flex-grow: unset !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.overflow-auto-modal {
  overflow: auto;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-centered-text {
  margin-top: auto;
  margin-bottom: auto;
}

.w-100p {
  width: 100% !important;
}

.w-100p-lt-lg {
    @include media-breakpoint('lt-lg') {
        width: 100% !important;
    }
}

.w-100p-lt-md {
    @include media-breakpoint('lt-md') {
        width: 100% !important;
    }
}

.w-100p-lt-sm {
    @include media-breakpoint('lt-sm') {
        width: 100% !important;
    }
}

.w-100 {
  width: 100px !important;
}

.version-text {
  font-size: 15px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture-viewer .mat-dialog-container {
  padding: 4px 4px 24px !important;
}

#category-panel-container {
  mat-expansion-panel {
    background: transparent;
  }
}

owl-date-time-container {
  font-size: 1.3rem !important;
}

.required-star {
  color: red;
}

#discount-editor {
  .mat-step-icon-content {
    display: none;
  }
}

.range-input {
  .mat-form-field-wrapper {
    margin-bottom: -1.25em;
    align-items: center;
  }
}

#pricing-values {
  .mat-form-field-wrapper {
    padding: 0 10px 0;
  }
}

// Summary component

.summary-list .mat-list-item {
  height: 20px !important;
}

//.mat-row:nth-child(even) {
//  background-color: #FFF !important;
//}
//
//.mat-row:nth-child(odd) {
//  background-color: #f7f7f7 !important;
//}
//
//body.theme-default mat-header-row {
//  background-color: #efefef !important;
//}

.mat-sort-header-button,
.mat-header-cell {
  font-weight: 600;
}

.custom-card {
  mat-card {
    padding: 0;

    mat-card-header {
      //background-color: #ececec !important;

      mat-card-title {
        margin-top: 10px;
      }
    }

    mat-card-content {
      padding: 5px 15px;
      overflow: auto;
    }
  }
}

.general-button-container {
  @extend .centered;

  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  margin-top: 20px;
}

.bordered-card {
  border: 1.5px solid lightgrey;

  mat-card-header {
    border-bottom: 1.5px solid lightgrey;
  }
}

#catalog-stepper-container {
  mat-step-header {
    pointer-events: none;
  }
}

.mat-standard-chip {
  border-radius: 4px !important;
}

.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 32px;
  height: 1px;
}

.mat-chip {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}

//#category-mapping-container {
//  .toggle-children-wrapper {
//    display: none !important;
//  }
//}

.mat-horizontal-stepper-header-container {
  padding-left: 40px !important;
  padding-right: 40px !important;

  @include media-breakpoint('lt-md') {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mat-step-header {
    border-radius: 8px;
    overflow: hidden;

    .mat-step-icon {
      .mat-icon {
        @extend .s-24;
      }
      background-color: unset !important;
      color: var(--app-syncee-grey-500) !important;

      &.mat-step-icon-selected {
        color: var(--app-black-500) !important;
      }
    }

    .mat-step-label {
      padding-top: 4px !important;
      color: var(--app-syncee-grey-500) !important;
      @extend .typography-body-2;

      &.mat-step-label-selected {
        color: var(--app-black-500) !important;
      }
    }
  }
}

.centered-text {
  text-align: center !important;
}

.bold-text {
  font-weight: bold !important;
}

.medium-text {
  font-weight: 500;
}

// for change the select dropdown position, use this in mat-select: 'disableOptionCentering panelClass="mat-select-display-change"'
.mat-select-display-change {
  margin-top: 30px !important;
}

app-progress-bar-chart {
  width: 100%;
}

.field-mapping-input {
  width: 100%;

  .mat-form-field-wrapper {
    padding: 0 !important;
    margin: 10px !important;
  }
}

.custom-fields {
  width: 100%;
}

/* OUTLINE MATERIAL INPUTS*/

.mat-form-field-appearance-outline:not(.textarea-content) {
  .mat-form-field-label {
  top: 50% !important;
  margin-top: 0 !important;
}}

  .mat-form-field-appearance-outline:not(.textarea-content).mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.3em) scale(0.75) !important;
  }

.mat-form-field-appearance-outline:not(.textarea-content) {
  input.mat-input-element {
  margin-top: 10px !important;
}}

.mat-form-field:not(.without-padding).mat-form-field-appearance-outline:not(.textarea-content) {
  .mat-form-field-infix {
  padding: 0.7em 0 0.7em 0 !important;
  border-top: 0;
}}

.mat-form-field-appearance-outline .mat-select {
  margin-top: 10px;
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: rgba(40, 110, 249, 0.54) !important;
  }

  .mat-slide-toggle-thumb {
    background-color: $syncee-blue-400 !important;
  }
}

.mat-slide-toggle.mat-slide-fixed-color {
  .mat-slide-toggle-bar {
    background-color: $syncee-blue-400 !important;
  }

  .mat-slide-toggle-thumb {
    background-color: #fafafa !important;
    border: 1px solid #ddd;
  }

  .mat-ripple-element {
    background-color: black !important;
  }
}
.input-with-short-field {
  .mat-form-field-infix {
    width: 150px;
  }
}

.input-with-auto-field {
  .mat-form-field-infix {
    width: auto;
  }
}

.input-without-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-underline {
      bottom: 0;
    }
  }
}

.input-without-padding-except-error {
  &:not(.mat-form-field-invalid) {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      .mat-form-field-underline {
        bottom: 0;
      }
    }
  }
}


.custom-form-field-wrapper {
 > .mat-form-field-wrapper {
   > .mat-form-field-flex {
      padding-top: 0;
     > .mat-form-field-infix {
        padding: 0;
        border: none;
      }
    }
   .mat-form-field-underline {
     background-color: transparent !important;
   }

   .mat-error {
     margin-left: 12px;
   }
  }

}



.phone-form-field-wrapper {
  .mat-form-field-underline {
    width: calc(100% - 104px);
    margin-left: 50px;
    height: 0 !important;
  }
  .mat-form-field-subscript-wrapper {
    margin-left: 108px;
  }
}

.select-without-label {
  .mat-form-field-flex {
    padding-top: 0 !important;
  }
  .mat-form-field-infix {
    border: 0 !important;
    padding: 14px 0 !important;
  }
  .mat-select-arrow-wrapper {
    transform: unset !important;
  }
}

.input-prefix-fix {
  .mat-form-field-prefix {
    top: 0 !important;
    padding-right: 2px;
  }
}

.mat-chip-input {
  .mat-chip-list-wrapper {
    max-height: 100px;
    overflow: auto;
  }
}

mat-horizontal-stepper {
  &.stepper-without-header {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }
}

.terms {
  .registration-checkbox {
    label {
      white-space: normal !important;
    }
  }
}

/*DEFAULT COLORS*/

.syncee-blue-color {
  color: $syncee-blue-400 !important;
}

.syncee-blue-chip {
  background-color: #dee9ff !important;
  color: #1963f8 !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $syncee-blue-400 !important;
}

//.mat-tab-labels, .mat-tab-label, .mat-tab-link {
//  color: #000 !important;
//}

//.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
//  background: #286ef9 !important;
//}

hr.custom-divider {
  border: none;
  border-top: 1px solid #ececec;
}

.mat-pseudo-checkbox-checked::after {
  width: 8px !important;
  height: 3px !important;
}

fuse-sidebar {
  &.inner-sidebar-menu {
    width: 220px !important;
    min-width: 220px !important;
    max-width: 220px !important;

    .nav {
      .nav-item {
        .active {
          font-weight: bold;
          color: $syncee-blue-400;
        }
        &:hover {
          color: $syncee-blue-400;
        }
      }
    }
  }
}

#onboard,
.onboard-dialog {
  .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: $syncee-blue-400 !important;
  }
  .mat-checkbox-label {
    color: #000 !important;
  }
}

#gettingStarted {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

#shippingSupplierDetailsDialog {
  //.toggle-children-wrapper{
  //  display: none !important;
  //}

  .node-wrapper {
    align-items: center;
    //border: 1px solid #ececec;
    padding: 5px;
    &:hover {
      background: #ececec;
    }
  }

  .node-content-wrapper {
    width: 100%;
  }
}

.syncee-finish-btn {
  background-color: #19b643 !important;
  color: white !important;
}

.dialog-close-icon {
  cursor: pointer;
}

.animateLazyLoadImage {
  opacity: 1 !important;
  -webkit-transition: opacity 1s linear;
  transition: opacity 1s linear;
}

.align-end {
  text-align: end;
}

.singe-image-dialog-container {
  .mat-dialog-container {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .intercom-lightweight-app-launcher {
    bottom: 96px !important;
  }
}

.badge-directive-container {
  position: relative;

  .badge {
    font-family: 'Inter', sans-serif;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: map-get($syncee-primary, 700);
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 50%;
  }

  .top {
    top: 0;
  }

  .bottom {
    bottom: 0;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }

  .with-number {
    width: 14px;
    height: 14px;
    font-size: 10px;
  }

  .without-number {
    width: 8px;
    height: 8px;
  }
}

.country-flag {
  width: auto;
  height: 12px;
  border: 1px solid map-get($syncee-grey, 100);
}

.icon-filled {
  font-family: 'Material Icons' !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: map-get($black, 500) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: map-get($black, 500) !important;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background-color: #b0b0b0 !important;
}

.color-unpaid {
  background-color: $color-unpaid;
}

.color-partially-paid {
  background-color: $color-partially-paid;
}

.color-paid {
  background-color: $color-paid;
}

.item-divider {
  border: 0;
  border-top: 1px solid var(--app-syncee-grey-200);
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
}

.color-darkish-grey {
  color: $darkish-grey !important;
}

.bg-grey--400 {
  background-color: $syncee-grey-400 !important;
}

.color-grey--300 {
  color: $syncee-grey-300 !important;
}

.bg-grey--300 {
  background-color: $syncee-grey-300 !important;
}

.fulfilment-indicator {
  width: 16px;
  height: 16px;
  border-radius: 32px;
  border: 4px solid;

  &--fulfilled {
    @extend .fulfilment-indicator;
    border-color: #b0ffae;
  }

  &--unfulfilled {
    @extend .fulfilment-indicator;
    border-color: #ffaeae;
  }
}

.mat-shipping-warning {
  color: #FF0000;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.no-text-decoration {
  text-decoration: none !important;
}

.light-tooltip {
  background: map-get($syncee-grey, 100) !important;
  color: map-get($syncee-grey, 700) !important;
}

.no-wrap {
  white-space: nowrap;
}

a {
  color: var(--app-black-500) !important;
  text-decoration: underline;
  cursor: pointer;
}

a:active, a:link, a:hover, a:visited {
  color: var(--app-black-500) !important;
}

.mat-expansion-panel-content {
  font-family: inherit;
}

button {
  font-family: inherit !important;
}

.mat-raised-button, .mat-button, .mat-flat-button, .mat-stroked-button {
  font-weight: 500 !important;
  line-height: 38px !important;
  padding: 0 32px !important;
}

.custom-elevation-z1 {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2) !important;
}

// fix display mat errors margin
.mat-form-field-subscript-wrapper {
  margin-top: 3px;
}


.custom-form-field {

  mat-error {
    font-size: 10.5px;
    padding: 0 1em;
  }
}

.mat-tab-label, .mat-tab-link {
  font-family: inherit !important;
}

.submenu {
  &.type-tab {
    .mat-tab-nav-bar {
      border-bottom: 0 !important;

      .mat-tab-header-pagination {
        box-shadow: unset !important;

        &.mat-tab-header-pagination-disabled {
          display: none;
        }

        &.mat-tab-header-pagination-before {
          position: relative;
          overflow: visible;

          &::after {
            content: "";
            background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255));
            height: 41px;
            width: 50px;
            position: absolute;
            top: 0;
            right: -40px;
          }
        }

        &.mat-tab-header-pagination-after {
          position: relative;
          overflow: visible;

          &::before {
            content: "";
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255));
            height: 100%;
            width: 50px;
            position: absolute;
            top: 0;
            left: -40px;
          }
        }
      }
    }

    .mat-tab-link {
      min-width: unset !important;
      padding: 0 15px !important;

      &.mat-tab-label-active {
        color: var(--app-syncee-primary-700);
      }
    }
  }
}
.button-with-loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 38px;
}

.button-with-icon {
  min-height: 40px;

  &.start {
    padding-left: 24px !important;
  }

  &.end {
    padding-right: 24px !important;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    line-height: 14px;
  }
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mat-autocomplete-panel {
  .mat-option {
    font-size: 14px;
  }
}

.inline-block {
  display: inline-block;
}

.m-h-0 {
  min-height: 0 !important;
}

.m-w-0 {
  min-width: 0 !important;
}

.b-r-8 {
  border-radius: 8px;
}

.b-r-4 {
  border-radius: 4px;
}

.overflowing-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-select-search-panel {
  box-shadow: none !important;
  border: 1px solid var(--app-grey-300) !important;
  border-radius: 8px !important;
}

.mat-input-element.custom-input-error::placeholder {
  color: var(--app-red-500) !important;
}

.mat-select.custom-input-error {
  .mat-select-placeholder {
    color: var(--app-red-500) !important;
  }
}

.static-landing-page {
  > *:not(.full-width) {
    padding-left: 200px;
    padding-right: 200px;

    @include media-breakpoint('lt-lg') {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .inner-container {
    padding-left: 200px;
    padding-right: 200px;

    @include media-breakpoint('lt-lg') {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.select-none {
  user-select: none;
}

.item-display-none {
  display: none !important;
}

.mat-slide-toggle.mat-slide-fixed-color .mat-slide-toggle-bar {
  background-color: var(--app-syncee-primary-700) !important;
}

ngx-avatar {
    .avatar-container {
      .avatar-content {
        height: 100% !important;
      }
    }
  }

a.link-without-underline {
  text-decoration: unset !important;

  &:hover {
    text-decoration: unset !important;
  }
}

.grecaptcha-badge {
  z-index: 9999 !important;
}
