@use '../../../../../../../../node_modules/@angular/material/index' as mat;
@use 'sass:map';

@mixin product-search-with-autocomplete-content-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .autocomplete-container {
    .terms-list {
      .term {
        .highlight-content {
          color: mat.get-color-from-palette($primary-palette, default);
        }
      }
    }
  }
}
