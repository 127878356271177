@mixin generate-typography-classes($typography) {
  @each $key, $value in $typography {
    @if not index($excluded-keys, $value) and type-of($value) == 'map' {
      .typography-#{$key} {
        font-size: map-get($value, font-size);
        line-height: map-get($value, line-height);
        font-weight: map-get($value, font-weight);
        letter-spacing: map-get($value, letter-spacing);
      }
    }
  }
}

$excluded-keys: (font-family)
