@use 'sass:map';
@use '@angular/material' as mat;

@mixin select-theme($theme) {
  $background: map-get($theme, background);
  $disabled-button-background: map-get($background, disabled-button);

  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .mat-optgroup-label {
    font-weight: 700;
    font-size: 14px;
    color: var(--app-grey-900);
  }

}