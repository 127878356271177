@use '@angular/material' as mat;

@mixin subscription-plan-box-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  app-subscription-plan-box {
    .subscription-plan-box-container {
      border: 1px solid var(--app-syncee-grey-200);
    }
  }
}
